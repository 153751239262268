export enum Router {
  // shared
  MainPage = '/',
  Login = 'login',
  Register = 'register',
  PassRecovery = 'passrecovery',
  PersonalPage = 'personalpage',

  Create = 'create',
  Edit = 'edit',
  Download = 'download',

  //menu
  Clip = 'clip',
  Playlist = 'playlist',
  Channel = 'channel',
  Owner = 'owner',
  ClipCompilation = 'clipcompilation',
  PlaylistCompilation = 'playlistcompilation',
  Source = 'source',
  Tag = 'tag',
  TagsForCompilations = 'tagsforcompilations',
  TagDirectoryForCompilations = 'tagdirectoryforcompilations',
  Theme = 'theme',
  Author = 'author',
  Translator = 'translator',
  Reader = 'reader',
  RegistryRelatedFeeds = 'registryrelatedfeeds',
  RelatedFeed = 'relatedfeed',
  Person = 'person',
  Section = 'section',
  Rubric = 'rubric',
  Classifications = 'classifications',
  Supermix = 'supermix',
  ThemeCatalog = 'themecatalog',
  ThemeOrder = 'themeorder',
  TagDirectory = 'tagdirectory',
  SemanticField = 'semanticfield',
  Keywords = 'keywords',
  Feed = 'feed',
  FeedsGroups = 'feed-groups',
  RibbonFilters = 'ribbonfilters',
  Contexts = 'contexts',
  ClientProfile = 'clientprofile',
  ExternalCustomers = 'externalcustomers',
  InternalCustomers = 'internalcustomers',
  CharacteristicsOfTheUser = 'characteristicsoftheuser',
  Rates = 'rates',
  Purchases = 'purchases',
  Contract = 'contract',
  Counterparties = 'counterparties',
  CounterpartyCandidates = 'counterpartycandidates',
  NotificationsForClients = 'notificationsforclients',
  SpecialProjects = 'specialprojects',
  BusinessStatistics = 'businessstatistics',

  // BusinessStatistics
  AllTransactions = 'all-transactions',
  ReportForCounterparty = 'report-for-counterparty',
  Directory = 'directory',
  Statistics = 'statistics',
  SupermixProfit = 'supermixProfit',
  Summary = 'summary',
  Banks = 'banks',
  Total = 'total',
  License = 'license',

  TechnicalHealth = 'technicalhealth',
  BookSeries = 'book-series',
  FAQ = 'faq',
  QuestionOrder = 'question-order',
}
