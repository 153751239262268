import * as yup from 'yup';

export enum ChannelFields {
  Name = 'name',
  Description = 'description',
  Img_id = 'img_id',
  Primary_image = 'primary_image_url',
  Primary_banner = 'primary_banner_url',
  Secondary_image = 'secondary_image_url',
  Owner_id = 'owner_id',
  Weight = 'weight',
  Is_active = 'is_active',
  UUID = 'uuid',
  Created_at = 'created_at',
  Updated_at = 'updated_at',
  Owner_type = 'owner_type',
  Owner_level = 'owner_level',
}

export const titleListRelationFeed = [
  'Плейлисты',
  'Другие каналы',
  'Редакторская лента',
];

export const validateChannelFields = yup.object().shape({
  name: yup.string().min(1).required(),
  primary_image_url: yup.string().required(),
  owner_id: yup.number().required(),
});
