export const tags = {
  author: 'author',
  price: 'price',
  currency: 'currency',
  rate: 'rate',
  counterparties: 'counterparties',
  translator: 'translator',
  owner: 'owner',
  tag: 'tag',
  topic: 'topic',
  person: 'person',
  reader: 'reader',
  playlist: 'playlist',
  channel: 'channel',
  feed: 'feed',
  collection: 'collection',
  compilations: 'compilations',
  rubric: 'rubric',
  clip: 'clip',
  feedGroup: 'feedGroup',
  feedRelated: 'feedRelated',
  algorithms: 'algorithms',
  filters: 'filters',
  topicsCatalog: 'topicsCatalog',
  tagsCatalog: 'tagsCatalog',
  bookSeries: 'bookSeries',
  massActions: 'massActions',
  metrics: 'metrics',
  rss: 'rss',
  contract: 'contract',
  keyword: 'keywords',
  supermix: 'supermix',
  question: 'question',
  topicQuestion: 'themes',
  restriction: 'restrictions',
  section: 'section',
  playlistsChannel: 'playlistsChannel',
  tagForCompilations: 'tagForCompilations',
};
