import cn from 'classnames';
import { useFormikContext } from 'formik';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Clip } from '../../../app/api/services/clips/types';
import { Playlist } from '../../../app/api/services/playlists/types';
import { IEntityMerging, IItemEntity } from '../../../app/global/types';
import { Router } from '../../../components';
import { DragAndDrop, Plus, Point, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { getTheIdArray } from '../../../utils';
import { ButtonShowAll } from '../../ButtonShowAll';
import { ClickShowContainer } from '../../ClickShowContainer';
import { ObligatoryField } from '../../ObligatoryField';
import { SelectWithOptions } from '../../SelectWithOptions';
import { Separator } from '../../Separator';
import { TextWithTooltip } from '../../TextWithTooltip';

import style from './AddManyEntityWithList.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Playlist[] | Clip[];
  field: string;
  items?: IEntityMerging[];
  label: string;
  linkToEditEntity: string;
  isObligatory?: boolean;
}

export const AddManyEntityWithList = ({
  searchEntity,
  items,
  options,
  label,
  field,
  linkToEditEntity,
  isObligatory = false,
}: IProps): JSX.Element => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [showAll, setShowAll] = useState(id ? false : true);

  const data = items
    ? items.map((i, index) => ({ entity: i, order: index + 1 }))
    : [];
  const [allSelectedOptions, setAllSelectedOptions] =
    useState<IItemEntity[]>(data);
  const { setFieldValue } = useFormikContext();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleTypeSelect = (option) => {
    setSelectedOption(null);

    const isInArray = !!allSelectedOptions.find(
      (item) => item?.entity?.id === option?.id
    );

    if (isInArray || option === null) {
      return;
    } else {
      option = {
        entity: option,
        order: allSelectedOptions.length + 1,
      };
      setAllSelectedOptions((prevState) => [...prevState, option]);
      setShow(false);
    }
  };

  const clickHandle = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const deleteHandler = (clickedOption) => {
    setAllSelectedOptions((prevSelected) => {
      const newSelected = prevSelected.filter(
        (option) => !_.isEqual(option, clickedOption)
      );

      return newSelected;
    });
  };

  useEffect(() => {
    if (field === 'playlist_ids') {
      setFieldValue(
        field,
        getTheIdArray(allSelectedOptions.map((item) => item.entity))
      );
    } else {
      setFieldValue(field, {
        [searchEntity]: getTheIdArray(
          allSelectedOptions.map((item) => item.entity)
        ),
      });
    }

    if (field === 'elements') {
      const arrayOfOptions = allSelectedOptions.map((element) => ({
        id: element.entity.id,
        position: null,
      }));

      setFieldValue(field, { [searchEntity]: arrayOfOptions });
    }

    setFieldValue('playlist', allSelectedOptions[0]?.entity);
  }, [allSelectedOptions, field, searchEntity, setFieldValue]);

  const [currentOption, setCurrentOption] = useState(null);

  const [showDnD, setShowDnD] = useState(false);

  const [startDrag, setStartDrag] = useState(false);

  const dragStartHandle = (e, option) => {
    setCurrentOption(option);
    setShowDnD(true);
    setStartDrag(true);
  };
  const dragEndHandle = (e) => {
    e.target.style.background = 'white';
  };
  const dragOverHandle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.background = 'lightgray';
  };
  const dropHandle = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    setAllSelectedOptions(
      allSelectedOptions
        .map((o) => ({
          ...o,
          order:
            o.entity.id === option.entity.id
              ? currentOption.order
              : o.entity.id === currentOption.entity.id
              ? option.order
              : o.order,
        }))
        .sort((a, b) => a.order - b.order)
    );
    e.target.style.background = 'white';
    setShowDnD(false);
    setStartDrag(false);
  };

  // const sortOptions = (a, b) => (a.order < b.order ? 1 : -1);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerAndPlus}>
          <h3 className={style.label}>
            {label} {isObligatory && <ObligatoryField />}
          </h3>
          <button className={style.add} onClick={(e) => clickHandle(e)}>
            <Plus />
          </button>
        </div>
      </div>

      <Separator />

      {show && (
        <ClickShowContainer
          dropdown={show}
          setDropdown={setShow}
          className={cn(style.select)}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
            value={selectedOption}
          />
        </ClickShowContainer>
      )}

      <div>
        <div className={style.selectedOptions}>
          {(allSelectedOptions.length < 9 || showAll
            ? allSelectedOptions
            : allSelectedOptions.slice(0, 9)
          ).map((option) => (
            <div
              key={option?.entity?.id}
              className={cn(style.option, {
                [style.startDrag]: startDrag,
              })}
              draggable
              onDragStart={(e) => dragStartHandle(e, option)}
              onDragLeave={(e) => dragEndHandle(e)}
              onDragEnd={(e) => dragEndHandle(e)}
              onDragOver={(e) => dragOverHandle(e)}
              onDrop={(e) => dropHandle(e, option)}
            >
              <div className={style.point}>
                <Point />
              </div>
              <Link
                className={style.name}
                to={`${linkToEditEntity}/${Router.Edit}/${option?.entity?.id}`}
              >
                <TextWithTooltip text={option?.entity?.name} />
              </Link>
              <button
                type="button"
                onClick={() => deleteHandler(option)}
                className={style.deleteButton}
              >
                <Trash />
              </button>
              {showDnD && option?.entity?.id === currentOption?.id && (
                <div className={style.DnDIcon}>
                  <DragAndDrop />
                </div>
              )}
            </div>
          ))}
        </div>
        {allSelectedOptions.length > 9 && (
          <ButtonShowAll showAll={showAll} setShowAll={setShowAll} />
        )}
      </div>
    </div>
  );
};
