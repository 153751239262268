import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useGetFeedGroupByConditionQuery } from '../../../../../../../../../app/api/services/feedGroups/feedGroups';
import { useGetFeedByConditionQuery } from '../../../../../../../../../app/api/services/feeds/feeds';
import { Element } from '../../../../../../../../../app/api/services/rubrics/types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../hooks';
import {
  addRubric,
  changeRubric,
  IRubricState,
} from '../../../../../../../../../store/slices/rubricSlice';
import {
  ClickShowContainer,
  FrameContext,
  SelectWithOptions,
} from '../../../../../../../../../widgets';
import { framesDescription } from '../../helpers';

import style from './EditRubricFrame.module.scss';

interface IProps {
  elementToEdit?: Element;
  position?: 'right' | 'left';
}

export const EditRubricFrame = ({
  elementToEdit,
  position,
}: IProps): JSX.Element => {
  const { contextOpened, setContextOpened } = useContext(FrameContext);
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [frameId, setFrameId] = useState('');
  const [currentOptions, setCurrentOptions] = useState(null);
  const [currentFrameOptionsId, setCurrentFrameOptionsId] = useState(null);

  const { data: feeds } = useGetFeedByConditionQuery({
    limit: 5,
    offset: 0,
    is_related: false,
  });
  const { data: feedsGroup } = useGetFeedGroupByConditionQuery({
    limit: 5,
    offset: 0,
  });
  const allElements = useAppSelector(
    (state) => (state.rubricReducer as IRubricState).list
  );
  const dispatch = useAppDispatch();

  const clickHandle = (frame) => {
    setCurrentOptions(() =>
      frame.id === 'feed' ? feeds?.response : feedsGroup?.response
    );
    setFrameId(frame.id);
    setCurrentFrameOptionsId(frame.optionsId);
    setShowSelect(true);
    setContextOpened(false);
  };

  const handleTypeSelect = (option) => {
    if (!option) {
      return;
    }

    setCurrentOptions(null);
    pushOptions(option);
    setShowSelect(false);
  };

  const pushOptions = (option) => {
    const isInArray = !!allElements.find(
      (element) => element?.[frameId]?.id === option.id
    );

    if (isInArray) {
      return;
    }

    elementToEdit
      ? dispatch(
          changeRubric({
            position: elementToEdit.position,
            entity_type: frameId,
            entity_id: option.id,
            entity: option,
          })
        )
      : dispatch(
          addRubric({
            position: allElements.length + 1,
            entity_type: frameId,
            entity_id: option.id,
            entity: option,
          })
        );
  };

  const setValue = useMemo(
    () =>
      allElements.map((element) => ({
        entity_id: element.entity_id,
        position: element.position,
        entity_type: element.entity_type,
      })),
    [allElements]
  );

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue('elements', setValue);
  }, [setFieldValue, setValue]);

  return (
    <div className={style.container}>
      {contextOpened && (
        <ClickShowContainer
          dropdown={contextOpened}
          setDropdown={setContextOpened}
          className={cn(style.select, { [style.left]: position === 'left' })}
        >
          <div className={style.frames}>
            {framesDescription.map((frame) => (
              <span
                key={frame.id}
                onClick={() => clickHandle(frame)}
                className={style.option}
              >
                {frame.title}
              </span>
            ))}
          </div>
        </ClickShowContainer>
      )}

      {showSelect && (
        <ClickShowContainer
          dropdown={showSelect}
          setDropdown={setShowSelect}
          className={cn(style.select, { [style.left]: position === 'left' })}
        >
          <SelectWithOptions
            searchEntity={currentFrameOptionsId}
            options={currentOptions}
            onChange={handleTypeSelect}
            withUUID={true}
          />
        </ClickShowContainer>
      )}
    </div>
  );
};
